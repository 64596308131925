import { Html } from "@elysiajs/html";

export const TV = () => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 13H2V2H20V13Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Calculate = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM10.03 4.06L11.09 3L12.5 4.41L13.91 3L14.97 4.06L13.56 5.47L14.97 6.88L13.91 7.94L12.5 6.54L11.09 7.95L10.03 6.89L11.44 5.48L10.03 4.06ZM3.25 4.72H8.25V6.22H3.25V4.72ZM8.5 13H6.5V15H5V13H3V11.5H5V9.5H6.5V11.5H8.5V13ZM15 14.25H10V12.75H15V14.25ZM15 11.75H10V10.25H15V11.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const MapPin = () => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Table = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9H4V5H0V9ZM0 14H4V10H0V14ZM0 4H4V0H0V4ZM5 9H18V5H5V9ZM5 14H18V10H5V14ZM5 0V4H18V0H5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Briefcase = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13V12H1.01L1 16C1 17.11 1.89 18 3 18H17C18.11 18 19 17.11 19 16V12H12V13H8ZM18 4H13.99V2L11.99 0H7.99L5.99 2V4H2C0.9 4 0 4.9 0 6V9C0 10.11 0.89 11 2 11H8V9H12V11H18C19.1 11 20 10.1 20 9V6C20 4.9 19.1 4 18 4ZM12 4H8V2H12V4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Pencil = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 14.2501V18.0001H3.75L14.81 6.94006L11.06 3.19006L0 14.2501ZM17.71 4.04006C18.1 3.65006 18.1 3.02006 17.71 2.63006L15.37 0.290059C14.98 -0.0999414 14.35 -0.0999414 13.96 0.290059L12.13 2.12006L15.88 5.87006L17.71 4.04006Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Trash = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Danger = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 0.333374C7.79998 0.333374 0.333313 7.80004 0.333313 17C0.333313 26.2 7.79998 33.6667 17 33.6667C26.2 33.6667 33.6666 26.2 33.6666 17C33.6666 7.80004 26.2 0.333374 17 0.333374ZM18.6666 25.3334H15.3333V22H18.6666V25.3334ZM18.6666 18.6667H15.3333V8.66671H18.6666V18.6667Z"
        fill="#FF3B30"
      />
    </svg>
  );
};

export const Close = (props: JSX.HtmlSvgTag) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9999 14.3999L3.59994 22.7999C3.28566 23.1142 2.88566 23.2714 2.39994 23.2714C1.91423 23.2714 1.51423 23.1142 1.19994 22.7999C0.885658 22.4857 0.728516 22.0857 0.728516 21.5999C0.728516 21.1142 0.885658 20.7142 1.19994 20.3999L9.59995 11.9999L1.19994 3.59994C0.885658 3.28566 0.728516 2.88566 0.728516 2.39994C0.728516 1.91423 0.885658 1.51423 1.19994 1.19994C1.51423 0.885658 1.91423 0.728516 2.39994 0.728516C2.88566 0.728516 3.28566 0.885658 3.59994 1.19994L11.9999 9.59995L20.3999 1.19994C20.7142 0.885658 21.1142 0.728516 21.5999 0.728516C22.0857 0.728516 22.4857 0.885658 22.7999 1.19994C23.1142 1.51423 23.2714 1.91423 23.2714 2.39994C23.2714 2.88566 23.1142 3.28566 22.7999 3.59994L14.3999 11.9999L22.7999 20.3999C23.1142 20.7142 23.2714 21.1142 23.2714 21.5999C23.2714 22.0857 23.1142 22.4857 22.7999 22.7999C22.4857 23.1142 22.0857 23.2714 21.5999 23.2714C21.1142 23.2714 20.7142 23.1142 20.3999 22.7999L11.9999 14.3999Z"
        fill="#717171"
      />
    </svg>
  );
};

export const Plus = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1996_3675)">
        <path
          d="M19.9868 13H13.9868V19H11.9868V13H5.98682V11H11.9868V5H13.9868V11H19.9868V13Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1996_3675">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.986816)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LogOut = () => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7083 4.29167L14.2396 5.76042L16.9271 8.45833H6.33331V10.5417H16.9271L14.2396 13.2292L15.7083 14.7083L20.9166 9.5L15.7083 4.29167ZM2.16665 2.20833H10.5V0.125H2.16665C1.02081 0.125 0.083313 1.0625 0.083313 2.20833V16.7917C0.083313 17.9375 1.02081 18.875 2.16665 18.875H10.5V16.7917H2.16665V2.20833Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Search = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9"
        cy="9"
        r="8"
        stroke="#4C5424"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 14.958L19.5 19.958"
        stroke="#4C5424"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ChevronDown = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1994_909)">
        <path
          d="M6.175 7.15845L10 10.9751L13.825 7.15845L15 8.33345L10 13.3334L5 8.33345L6.175 7.15845Z"
          fill="#080804"
        />
      </g>
      <defs>
        <clipPath id="clip0_1994_909">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CloseSmall = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4141 11.9998L17.7071 7.70676C18.0981 7.31576 18.0981 6.68376 17.7071 6.29276C17.3161 5.90176 16.6841 5.90176 16.2931 6.29276L12.0001 10.5858L7.70713 6.29276C7.31613 5.90176 6.68413 5.90176 6.29313 6.29276C5.90213 6.68376 5.90213 7.31576 6.29313 7.70676L10.5861 11.9998L6.29313 16.2928C5.90213 16.6838 5.90213 17.3158 6.29313 17.7068C6.48813 17.9018 6.74413 17.9998 7.00013 17.9998C7.25613 17.9998 7.51213 17.9018 7.70713 17.7068L12.0001 13.4138L16.2931 17.7068C16.4881 17.9018 16.7441 17.9998 17.0001 17.9998C17.2561 17.9998 17.5121 17.9018 17.7071 17.7068C18.0981 17.3158 18.0981 16.6838 17.7071 16.2928L13.4141 11.9998Z"
        fill="#18203A"
      />
      <mask
        id="mask0_1567_2028"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="14"
        height="13"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.4141 11.9998L17.7071 7.70676C18.0981 7.31576 18.0981 6.68376 17.7071 6.29276C17.3161 5.90176 16.6841 5.90176 16.2931 6.29276L12.0001 10.5858L7.70713 6.29276C7.31613 5.90176 6.68413 5.90176 6.29313 6.29276C5.90213 6.68376 5.90213 7.31576 6.29313 7.70676L10.5861 11.9998L6.29313 16.2928C5.90213 16.6838 5.90213 17.3158 6.29313 17.7068C6.48813 17.9018 6.74413 17.9998 7.00013 17.9998C7.25613 17.9998 7.51213 17.9018 7.70713 17.7068L12.0001 13.4138L16.2931 17.7068C16.4881 17.9018 16.7441 17.9998 17.0001 17.9998C17.2561 17.9998 17.5121 17.9018 17.7071 17.7068C18.0981 17.3158 18.0981 16.6838 17.7071 16.2928L13.4141 11.9998Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1567_2028)"></g>
    </svg>
  );
};

export const Eye = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3C5.45455 3 1.57273 5.82727 0 9.81818C1.57273 13.8091 5.45455 16.6364 10 16.6364C14.5455 16.6364 18.4273 13.8091 20 9.81818C18.4273 5.82727 14.5455 3 10 3ZM10 14.3636C7.49091 14.3636 5.45455 12.3273 5.45455 9.81818C5.45455 7.30909 7.49091 5.27273 10 5.27273C12.5091 5.27273 14.5455 7.30909 14.5455 9.81818C14.5455 12.3273 12.5091 14.3636 10 14.3636ZM10 7.09091C8.49091 7.09091 7.27273 8.30909 7.27273 9.81818C7.27273 11.3273 8.49091 12.5455 10 12.5455C11.5091 12.5455 12.7273 11.3273 12.7273 9.81818C12.7273 8.30909 11.5091 7.09091 10 7.09091Z"
        fill="#080804"
      />
    </svg>
  );
};

export const CloseEye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-eye-off"
    >
      <path d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49" />
      <path d="M14.084 14.158a3 3 0 0 1-4.242-4.242" />
      <path d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143" />
      <path d="m2 2 20 20" />
    </svg>
  );
};
